import { ErrorTracking } from "../error_tracking/error_tracking";
import Web from "../utils/networking";
import { createUrl, Endpoints } from "./endpoints";

//TODO: Refactor this to make it more scalable
interface AuthResponseData {
  created: boolean;
  user_id: string;
  token: string;
}

interface AuthResult {
  success: boolean;
  created?: boolean;
  userId?: string;
  token?: string;
}

interface ProposalResponseData {
  proposals: RawProposalData[];
}

type GetProposalData = {
  proposals: RawProposalData[];
  error?: string;
};

interface ArtifactLocations {
  decentralised_metadata_location: string;
  decentralised_zipfile_location: string;
  zipfile_location: string;
}
interface RawProposalData {
  uid: string;
  name: string;
  user_id: string;
  blockchain_transaction_id?: string;
  metadata: object;
  artifact_locations: ArtifactLocations;
  creator_name: string;
  description: string;
  date_created: string;
  hashed_contents?: string;
  files: RawProposalFileData[];
  token_id?: string;
  status: string;
}

type RawProposalFileData = {
  uid: string;
  type: string;
  date_created: string;
  public_url: string;
  key: string;
  name: string;
};

type AuthenticatedRequestProps = {
  token: string;
};

type GetProposalProps = {
  userId: string;
} & AuthenticatedRequestProps;

type CreateProposalProps = {
  creatorName: string;
  proposalName: string;
  description: string;
  metadata?: { key: string; value: string }[];
} & AuthenticatedRequestProps;

type CreateProposalResponse = {
  proposal_uid: string;
};

type CreateProposalData = {
  proposalUid: string;
};

export type GenericResponse<T = null> = {
  success: boolean;
  payload?: T;
  error?: string;
};

type AddFileProps = {
  formData: FormData;
} & AuthenticatedRequestProps;

type CommitProposalProps = {
  proposalUid: string;
} & AuthenticatedRequestProps;

type CommitProposalData = {
  proposalHash: string;
  metadataLocation: string;
};

type ConfirmProposalProps = {
  proposalUid: string;
  token: string;
  blockchainTransactionId: string;
  contractAddress: string;
};

type ProposalData = {
  proposals: RawProposalData[];
};

class Backend {
  static async authenticate(
    publicKey: string,
    message: string,
    signature: string
  ): Promise<AuthResult> {
    const url = createUrl(Endpoints.Authenticate);

    return Web.post(url, {
      public_key: publicKey,
      message: message,
      signature: signature,
    })
      .then((data: AuthResponseData) => {
        return {
          success: true,
          created: data.created,
          userId: data.user_id,
          token: data.token,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("authenticate");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
        };
      });
  }

  static async getProposalsByUser({
    token,
    userId,
  }: GetProposalProps): Promise<GenericResponse<ProposalData>> {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = createUrl(Endpoints.Proposals);

    return Web.get(url, { user_uid: userId }, headers)
      .then((data: ProposalResponseData) => {
        return {
          success: true,
          payload: {
            proposals: data.proposals,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("getProposalsByUser");
        ErrorTracking.errorBreadcrumb(`${error}`);

        return {
          success: false,
          error: "Failed to fetch proposals",
        };
      });
  }

  static async createProposal({
    creatorName,
    proposalName,
    description,
    metadata,
    token,
  }: CreateProposalProps): Promise<GenericResponse<CreateProposalData>> {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = createUrl(Endpoints.CreateProposal);

    return Web.post(
      url,
      {
        name: proposalName,
        description,
        creator_name: creatorName,
        custom_metadata: metadata,
      },
      headers
    )
      .then((response: CreateProposalResponse) => {
        return {
          success: true,
          payload: {
            proposalUid: response.proposal_uid,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("createProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to create proposal",
        };
      });
  }

  static async addFileToProposal({
    formData,
    token,
  }: AddFileProps): Promise<GenericResponse> {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = createUrl(Endpoints.AttachFile);

    return Web.post(url, formData, headers)
      .then((response: CreateProposalResponse) => {
        return {
          success: true,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("addFileToProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to add file proposal",
        };
      });
  }

  static async commitProposal({
    proposalUid,
    token,
  }: CommitProposalProps): Promise<GenericResponse<CommitProposalData>> {
    const headers = { Authorization: `Token ${token}` };
    const url = createUrl(Endpoints.CommitProposal);

    return Web.patch(
      url,
      {
        proposal_uid: proposalUid,
      },
      headers
    )
      .then((response) => {
        return {
          success: true,
          payload: {
            proposalHash: response.proposal_hash,
            metadataLocation: response.metadata_location,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("commitProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to commit proposal to the blockchain",
        };
      });
  }

  static confirmProposal = ({
    proposalUid,
    token,
    blockchainTransactionId,
    contractAddress,
  }: ConfirmProposalProps): Promise<GenericResponse> => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = createUrl(Endpoints.ConfirmProposal);

    return Web.patch(
      url,
      {
        proposal_uid: proposalUid,
        blockchain_transaction_id: blockchainTransactionId,
        contract_address: contractAddress,
      },
      headers
    )
      .then(() => {
        return {
          success: true,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("confirmProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to confirm proposal",
        };
      });
  };
}

export type { AuthResult, ProposalResponseData, RawProposalData };
export { Backend };
