import path from "path";

export enum Endpoints {
  Authenticate = "/users/auth",
  Proposals = "/proposals/proposals-by-user",
  CreateProposal = "/proposals/proposal",
  AttachFile = "/proposals/file",
  CommitProposal = "/proposals/commit",
  ConfirmProposal = "/proposals/confirm",
}

export const createUrl = (endpoint: Endpoints) => {
  const BASE_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
  return new URL(path.join(endpoint), BASE_URL).toString();
};
