import { Spinner } from "reactstrap";

import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.spinnerContainer}>
        <h3 className={styles.title}>Blockposal</h3>
        <Spinner></Spinner>
      </div>
    </div>
  );
};

export default Loading;
