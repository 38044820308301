import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

const errorHandler = (error: any) => {
  const responseCode = error.response.status;
  const responseCodeFamily = Math.floor(responseCode / 100) * 100;

  switch (responseCodeFamily) {
    case 400: {
      console.log("400 received | ", error);
      break;
    }
    case 500: {
      console.log("500 received | ", error);
      break;
    }
    default: {
      console.log("other http error received | ", error);
    }
  }
  throw error;
};

class Web {
  static async post(
    url: string,
    data: object,
    headers?: AxiosRequestHeaders
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    return axios
      .post(url, data, config)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  }

  static async get(
    url: string,
    params: any,
    headers: AxiosRequestHeaders
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: headers,
      params: params,
    };

    return axios
      .get(url, config)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  }

  static async patch(
    url: string,
    data: object,
    headers?: AxiosRequestHeaders
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    return axios
      .patch(url, data, config)
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  }
}

export default Web;
